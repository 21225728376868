import {
  FETCH_AC_SHIPPERS_BEGIN,
  FETCH_AC_SHIPPERS_SUCCESS,
  FETCH_AC_SHIPPERS_FAILURE,
  FETCH_TIERS_BEGIN,
  FETCH_TIERS_SUCCESS,
  FETCH_TIERS_FAILURE,
  RUN_REPORT_BEGIN,
  RUN_REPORT_FAILURE,
  RUN_REPORT_SUCCESS,
  TEST_REPORT_BEGIN,
  TEST_REPORT_FAILURE,
  TEST_REPORT_SUCCESS,
  FETCH_ZONES_BEGIN,
  FETCH_ZONES_SUCCESS,
  FETCH_ZONES_FAILURE,
  DELETE_ZONES_SUCCESS,
  DELETE_ZONES_FAILURE,
  FETCH_ZONES_BY_ID_BEGIN,
  FETCH_ZONES_BY_ID_SUCCESS,
  FETCH_ZONES_BY_ID_FAILURE,
  UPDATE_ZONES_BEGIN,
  UPDATE_ZONES_FAILURE,
  UPDATE_ZONES_SUCCESS,
  CREATE_ZONES_BEGIN,
  CREATE_ZONES_FAILURE,
  CREATE_ZONES_SUCCESS,
  FETCH_COUNTRY_SUCCESS,
  FETCH_COUNTRY_FAILURE,
  FETCH_SHIPPERSERVICE_PRICES_BEGIN,
  FETCH_SHIPPERSERVICE_PRICES_SUCCESS,
  FETCH_SHIPPERSERVICE_PRICES_FAILURE,
  DELETE_SHIPPERSERVICE_PRICES_SUCCESS,
  DELETE_SHIPPERSERVICE_PRICES_FAILURE,
  FETCH_SHIPPERSERVICE_PRICES_BY_ID_SUCCESS,
  FETCH_SHIPPERSERVICE_PRICES_BY_ID_FAILURE,
  UPDATE_SHIPPERSERVICE_PRICES_BEGIN,
  UPDATE_SHIPPERSERVICE_PRICES_FAILURE,
  UPDATE_SHIPPERSERVICE_PRICES_SUCCESS,
  CREATE_SHIPPERSERVICE_PRICES_BEGIN,
  CREATE_SHIPPERSERVICE_PRICES_FAILURE,
  CREATE_SHIPPERSERVICE_PRICES_SUCCESS,
  FETCH_SHIPPERSERVICE_MARGINS_BEGIN,
  FETCH_SHIPPERSERVICE_MARGINS_SUCCESS,
  FETCH_SHIPPERSERVICE_MARGINS_FAILURE,
  DELETE_SHIPPERSERVICE_MARGINS_SUCCESS,
  DELETE_SHIPPERSERVICE_MARGINS_FAILURE,
  FETCH_SHIPPERSERVICE_MARGINS_BY_ID_SUCCESS,
  FETCH_SHIPPERSERVICE_MARGINS_BY_ID_FAILURE,
  UPDATE_SHIPPERSERVICE_MARGINS_BEGIN,
  UPDATE_SHIPPERSERVICE_MARGINS_FAILURE,
  UPDATE_SHIPPERSERVICE_MARGINS_SUCCESS,
  CREATE_SHIPPERSERVICE_MARGINS_BEGIN,
  CREATE_SHIPPERSERVICE_MARGINS_FAILURE,
  CREATE_SHIPPERSERVICE_MARGINS_SUCCESS,
  FETCH_PICKING_BEGIN,
  FETCH_PICKING_SUCCESS,
  FETCH_PICKING_FAILURE,
  DELETE_PICKING_SUCCESS,
  DELETE_PICKING_FAILURE,
  FETCH_PICKING_BY_RANGE_SUCCESS,
  FETCH_PICKING_BY_ID_FAILURE,
  UPDATE_PICKING_BEGIN,
  UPDATE_PICKING_FAILURE,
  UPDATE_PICKING_SUCCESS,
  CREATE_PICKING_BEGIN,
  CREATE_PICKING_FAILURE,
  CREATE_PICKING_SUCCESS,
  CLEAR_AC_SHIPPERS,
  CLEAR_ZONES,
  CLEAR_SHIPPING_NOTIFICATION,
  CLEAR_SHIPPERSERVICE_STATE,
} from "actions/admin/shippingActions";

// specify initial state
const initialState = {
  shippers: [],
  shipperZones: [],
  shipperServicePrices: [],
  tabs: [],
  result: {},
  filters: {},
  loading: false,
  error: null,
  notification: null,
  dummyData: [...Array(10)],
  formSuccess: false,

  zones: [],
  countries: [],
  fetchedZone: {},

  shipperServiceMargins: [],
  shipperServiceMarginsL1: [],
  shipperServiceMarginsL2: [],
  shipperServiceMarginsL3: [],
  shipperServiceMarginsL4: [],
  shipperDetail: {},
};

/*views->actions->reducers(state)
    ^               |
    |---------------|
*/
export default function shippingReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_AC_SHIPPERS_BEGIN:
      return {
        ...state,
        loading: true,
        formSuccess: false,
        error: null,
        notification: null,
      };
    case FETCH_AC_SHIPPERS_SUCCESS:
      return {
        ...state,
        loading: false,
        shippers: action.data.shippers,
        header: action.data.header,
        filters: action.data.filters,
      };
    case FETCH_AC_SHIPPERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        shippers: [],
      };
    case CLEAR_AC_SHIPPERS:
      return {
        ...state,
        ...initialState,
      };
    case FETCH_TIERS_BEGIN:
      return {
        ...state, //imp line
        loading: true,
        formSuccess: false,
        error: null,
        notification: null,
      };

    case FETCH_TIERS_SUCCESS:
      return {
        ...state,
        loading: false,
        shipperZones: action.data.shipper_zones,
        filters: action.data.filters,
        shipperDetail: action.data.shipper,
        tabs: action.data.tab_names,
      };

    case FETCH_TIERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        shipperZones: [],
        filters: [],
      };
    case RUN_REPORT_BEGIN: {
      return {
        ...state,
        formSuccess: false,
        loading: true,
        error: null,
        notification: null,
        result: null,
      };
    }
    case RUN_REPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
        errors: null,
        //shippers: action.payload.shipper,
        tabs: action.payload.tab_names,
        shipperServicePrices: action.payload,
      };
    }
    case RUN_REPORT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    }
    case TEST_REPORT_BEGIN:
      return {
        ...state,
        loading: true,
        formSuccess: false,
        error: null,
        notification: null,
        shipperServicePrices: null,
      };

    case TEST_REPORT_SUCCESS: {
      return {
        ...state,
        errors: null,
        loading: false,
        result: action.payload.result.shipper_service_price,
        filters: action.payload.filters,
      };
    }
    case TEST_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        formLoading: false,
        formSuccess: false,
        error: action.payload.error,
      };
    /*---------------------Zones----------------------------*/
    case FETCH_ZONES_BEGIN:
      return {
        ...state, //imp line
        loading: true,
        formSuccess: false,
        error: null,
        notification: null,
      };

    case FETCH_ZONES_SUCCESS:
      return {
        ...state,
        loading: false,
        zones: action.data.shipper_zones,
        tabs: action.data.tab_names,
        shipperDetail: action.data.shipper,
      };

    case FETCH_ZONES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        zones: [],
      };
    case DELETE_ZONES_SUCCESS:
      return {
        ...state,
        error: null,
        zones: state.zones.filter((zones) => zones.id !== action.data.id),
        notification: action.msg,
      };
    case DELETE_ZONES_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        zones: state.zones,
        notification: action.msg,
      };

    case FETCH_ZONES_BY_ID_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case FETCH_ZONES_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        fetchedZone: action.payload,
      };

    case FETCH_ZONES_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        notification: action.msg,
      };
    case UPDATE_ZONES_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null,
        notification: null,
        formSuccess: false,
      };
    }
    case UPDATE_ZONES_SUCCESS: {
      return {
        ...state,
        loading: false,
        formSuccess: true,
        error: null,
        updateZoneErrors: null,
        fetchedZone: action.payload,
      };
    }
    case FETCH_COUNTRY_SUCCESS: {
      return {
        ...state,
        countries: action.countries,
      };
    }
    case FETCH_COUNTRY_FAILURE: {
      return {
        ...state,
        countries: [],
      };
    }
    case UPDATE_ZONES_FAILURE: {
      return {
        ...state,
        loading: false,
        formSuccess: false,
        error: action.payload.error,
        //updateZoneErrors: update_zones_failure_errors
      };
    }
    case CREATE_ZONES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
        notification: null,
        formSuccess: false,
      };

    case CREATE_ZONES_SUCCESS: {
      return {
        ...state,
        error: null,
        formSuccess: true,
        loading: false,
      };
    }
    case CREATE_ZONES_FAILURE:
      return {
        ...state,
        loading: false,
        formSuccess: false,
        error: action.payload.error,
      };

    case CLEAR_ZONES:
      return {
        ...state,
        loading: false,
        formSuccess: false,
        zones: [],
        fetchedZone: {},
      };

    case CLEAR_SHIPPING_NOTIFICATION:
      return {
        ...state,
        loading: false,
        formSuccess: false,
        error: null,
        notification: null,
      };
    /*---------------------ShipperServicePrices----------------------------*/
    case FETCH_SHIPPERSERVICE_PRICES_BEGIN:
      return {
        ...state, //imp line
        loading: true,
        formSuccess: false,
        error: null,
        notification: null,
      };

    case FETCH_SHIPPERSERVICE_PRICES_SUCCESS:
      return {
        ...state,
        loading: false,
        shipperDetail:  { ...(action?.data?.shipper|| {})},
        tabs:[ ...(action.data.tab_names || [])],
        shipperServicePrices: action.data,
      };

    case FETCH_SHIPPERSERVICE_PRICES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        shipperServicePrices: [],
      };

      case CLEAR_SHIPPERSERVICE_STATE:
        return{
          ...state,
        shipperDetail: {},
        tabs:[],
        shipperServicePrices:[] ,
        };

    case UPDATE_SHIPPERSERVICE_PRICES_BEGIN: {
      return {
        ...state,
        formLoading: true,
        formSuccess: false,
        error: null,
        notification: null,
      };
    }
    case UPDATE_SHIPPERSERVICE_PRICES_SUCCESS: {
      return {
        ...state,
        formLoading: false,
        formSuccess: true,
        errors: null,
        updateShipperServicePricesErrors: null,
        fetchedShipperServicePrices: action.payload,
        shipperServicePrices: action.payload,
      };
    }
    case UPDATE_SHIPPERSERVICE_PRICES_FAILURE: {
      return {
        ...state,
        formLoading: false,
        formSuccess: false,
        error: action.payload.error,
      };
    }
    case CREATE_SHIPPERSERVICE_PRICES_BEGIN:
      return {
        ...state,
        formLoading: true,
        formSuccess: false,
        error: null,
        notification: null,
      };

    case CREATE_SHIPPERSERVICE_PRICES_SUCCESS: {
      return {
        ...state,
        errors: null,
        formSuccess: true,
        formLoading: false,
        shipperServicePrices: [
          action.payload.shipperServicePrices,
          ...state.shipperServicePrices,
        ],
      };
    }
    case CREATE_SHIPPERSERVICE_PRICES_FAILURE:
      return {
        ...state,
        formLoading: false,
        formSuccess: false,
        error: action.payload.error,
      };

    /*---------------------ShipperServiceMargins----------------------------*/
    case FETCH_SHIPPERSERVICE_MARGINS_BEGIN:
      return {
        ...state, //imp line
        //loading: true,
        formSuccess: false,
        error: null,
        notification: null,
      };

    case FETCH_SHIPPERSERVICE_MARGINS_SUCCESS:
      let margin = {};
      if (action.level === 1) {
        margin.shipperServiceMarginsL1 = action.data;
      } else if (action.level === 2) {
        margin.shipperServiceMarginsL2 = action.data;
      } else if (action.level === 3) {
        margin.shipperServiceMarginsL3 = action.data;
      } else if (action.level === 4) {
        margin.shipperServiceMarginsL4 = action.data;
      }
      return {
        ...state,
        loading: false,
        ...margin,
      };

    case FETCH_SHIPPERSERVICE_MARGINS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        shipperServiceMargins: [],
      };
    case DELETE_SHIPPERSERVICE_MARGINS_SUCCESS:
      return {
        ...state,
        error: null,
        shipperServiceMargins: state.shipperServiceMargins.filter(
          (inbound) => inbound.id !== action.data.id
        ),
        notification: action.msg,
      };
    case DELETE_SHIPPERSERVICE_MARGINS_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        shipperServiceMargins: state.inbound,
        notification: action.msg,
      };

    case FETCH_SHIPPERSERVICE_MARGINS_BY_ID_SUCCESS:
      return {
        ...state,
        //childLoading: false,
        fetchedShipperServiceMargins: action.payload,
      };

    case FETCH_SHIPPERSERVICE_MARGINS_BY_ID_FAILURE:
      return {
        ...state,
        //childLoading: false,
        error: action.payload.error,
        notification: action.msg,
      };
    case UPDATE_SHIPPERSERVICE_MARGINS_BEGIN: {
      return {
        ...state,
        formLoading: true,
        formSuccess: false,
        error: null,
        notification: null,
      };
    }
    case UPDATE_SHIPPERSERVICE_MARGINS_SUCCESS: {
      let updatedShipperServiceMarginss = state.shipperServiceMargins.map(
        (item) => (item.id === action.payload.id ? action.payload : item)
      );
      return {
        ...state,
        formLoading: false,
        formSuccess: true,
        errors: null,
        updateShipperServiceMarginsErrors: null,
        fetchedShipperServiceMargins: action.payload,
        shipperServiceMargins: updatedShipperServiceMarginss,
      };
    }
    case UPDATE_SHIPPERSERVICE_MARGINS_FAILURE: {
      return {
        ...state,
        formLoading: false,
        formSuccess: false,
        error: action.payload.error,
      };
    }
    case CREATE_SHIPPERSERVICE_MARGINS_BEGIN:
      return {
        ...state,
        formLoading: true,
        formSuccess: false,
        error: null,
        notification: null,
      };

    case CREATE_SHIPPERSERVICE_MARGINS_SUCCESS: {
      return {
        ...state,
        errors: null,
        formSuccess: true,
        formLoading: false,
        shipperServiceMargins: [action.payload.inbound, ...state.inbound],
      };
    }
    case CREATE_SHIPPERSERVICE_MARGINS_FAILURE:
      return {
        ...state,
        formLoading: false,
        formSuccess: false,
        error: action.payload.error,
      };

    /*---------------------Picking----------------------------*/
    case FETCH_PICKING_BEGIN:
      return {
        ...state, //imp line
        loading: true,
        formSuccess: false,
        error: null,
        notification: null,
      };

    case FETCH_PICKING_SUCCESS:
      return {
        ...state,
        loading: false,
        picks: action.data.picks,
        marginRanges: action.data.margin_ranges,
        volumeRanges: action.data.volume_ranges,
        pickTierMargins: action.data.pick_tier_margins,
      };

    case FETCH_PICKING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        picks: {},
      };
    case DELETE_PICKING_SUCCESS:
      return {
        ...state,
        error: null,
        //picks: action.data,
        notification: action.msg,
      };
    case DELETE_PICKING_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        picks: state.picks,
        notification: action.msg,
      };

    case FETCH_PICKING_BY_RANGE_SUCCESS:
      return {
        ...state,
        //childLoading: false,
        fetchedPicking: action.payload,
      };

    case FETCH_PICKING_BY_ID_FAILURE:
      return {
        ...state,
        //childLoading: false,
        error: action.payload.error,
        notification: action.msg,
      };
    case UPDATE_PICKING_BEGIN: {
      return {
        ...state,
        formLoading: true,
        formSuccess: false,
        error: null,
        notification: null,
      };
    }
    case UPDATE_PICKING_SUCCESS: {
      return {
        ...state,
        formLoading: false,
        formSuccess: true,
        errors: null,
        updatePickingErrors: null,
        fetchedPicking: action.payload,
      };
    }
    case UPDATE_PICKING_FAILURE: {
      return {
        ...state,
        formLoading: false,
        formSuccess: false,
        error: action.payload.error,
      };
    }
    case CREATE_PICKING_BEGIN:
      return {
        ...state,
        formLoading: true,
        formSuccess: false,
        error: null,
        notification: null,
      };

    case CREATE_PICKING_SUCCESS: {
      return {
        ...state,
        errors: null,
        formSuccess: true,
        formLoading: false,
      };
    }
    case CREATE_PICKING_FAILURE:
      return {
        ...state,
        formLoading: false,
        formSuccess: false,
        error: action.payload.error,
      };
    default:
      // Always have a default case in case a reducer doesn't complete
      return state;
  }
}
