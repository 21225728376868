import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {reverseUserRoute} from "routes";
import {updateSearchValue, destroyNewOrder} from "actions/orderActions";

import OrdersSearch from "components/Search/OrdersSearch";
import BasicDrawer from "components/Drawers/BasicDrawer";
import OrderFilterDrawerContents from "views/user/Orders/OrderFilterDrawerContents";
import MenuLink from "components/Menu";

import {Button, Menu, MenuItem, makeStyles} from "@material-ui/core";

const OrdersMenuBar = React.memo((props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [filterPanelOpen, setFilterPanelOpen] = useState(false);
  const [newOrderAnchor, setNewOrderAnchor] = useState(null);
  const searchTerm = props.searchTerm;
  const saveFilter = props.saveFilter;
  const newOrderObject = useSelector(
    (state) => state.orderReducer.newOrderObject
  );

  const toggleDrawer = (side, open) => () => {
    setFilterPanelOpen(open);
  };

  const newOrderButton = (event) => {
    let target = event.currentTarget;
    setNewOrderAnchor(target);
  };

  const newOrderMenuClose = (destroy) => {
    setNewOrderAnchor(null);
    if(destroy) dispatch(destroyNewOrder());
  };

  const handleInputChange = (text) => {
    props.updateState("searchTerm", text);

  };
  const handleSaveFilter = (event) => {
    let userPref = JSON.parse(window.localStorage.getItem('userPref'));
    userPref.filterPref = {
      inOuts: props.inOuts,
      statuses: props.statuses
    }

    window.localStorage.setItem("userPref", JSON.stringify(userPref));
    props.updateState("saveFilter", false);
  }
  return (
    <div className={classes.root}>
      <OrdersSearch
        searchTerm={searchTerm}
        onChange={handleInputChange}
        placeholder="Search..."
        updateState={props.updateState}
      />
      <div className={classes.buttonsContainer}>
        <Button
          className={classes.button}
          aria-controls="Order-Menu"
          aria-haspopup="true"
          onClick={newOrderButton}
          variant="contained"
          color="primary"
        >
          Add Order
        </Button>

        <Menu
          id="newOrderMenu"
          anchorEl={newOrderAnchor}
          keepMounted
          open={Boolean(newOrderAnchor)}
          onClose={() => newOrderMenuClose(false)}
        >
          <MenuItem className={classes.menuItem}>
            <MenuLink
              to={`${reverseUserRoute("Orders")}summary/create_outbound`}
              onClick={() => newOrderMenuClose(true)}
            >
              New Outbound Order
            </MenuLink>
          </MenuItem>
          <MenuItem className={classes.menuItem}>
            <MenuLink
              to={`${reverseUserRoute("Orders")}summary/create_inbound`}
              onClick={() => newOrderMenuClose(true)}
            >
              New Inbound Order
            </MenuLink>
          </MenuItem>
          <MenuItem
            className={classes.menuItem}
            disabled={!Boolean(newOrderObject.orderType)}
          >
            <MenuLink
              to={`${reverseUserRoute("Orders")}summary/create_${newOrderObject.orderType
                }`}
              onClick={() => newOrderMenuClose(false)}
            >
              Continue Previous Order
            </MenuLink>
          </MenuItem>
        </Menu>

        {/* <Link to='add/'>
                      <Button
                          style={{marginRight: '8px'}}
                          onClick={(event => this.setState({classicView: false}))}
                          variant="contained"
                          color="#00b0c1"
                          >
                          {this.props.newOrderInProgress? 'Resume Order': 'Add Order'}
                      </Button>
                  </Link> */}

        <Button
          className={classes.button}
          onClick={toggleDrawer("right", true)}
          variant="contained"
        >
          Show Filters
        </Button>
      </div>

      <BasicDrawer
        open={filterPanelOpen}
        onClose={toggleDrawer("right", false)}
      >

        {saveFilter && <Button variant="contained" size="small" color="primary" className={classes.saveFilterBtn} onClick={handleSaveFilter}>
          Save Filter
        </Button>}


        <OrderFilterDrawerContents
          handleClearFilters={props.handleClearFilters}

          updateState={props.updateState}
          {...props}
        />
      </BasicDrawer>
    </div>
  );
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  button: {
    minWidth: "150px",
    margin: 5,
  },
  saveFilterBtn: {
    position: "absolute",
    right: "15px",
    top: "12px"
  },

  circularProg: {
    width: '200px',
    height: '200px'
  },

  jobStatusDialogHeading: {
    fontSize: '28px',

  }
}));

export default OrdersMenuBar;
